import { createRef, useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import bezier from "utils/bezier-easing/bezier-easing";
import { usePortfolioScreenStyles } from "./portfolioScreenStyles";
import { portfolioData } from "./portfolioData";
import Image from "next/image";
import { useElementInViewport } from "utils/useElementInViewport";

const lineRechtagleHeight = 20;

export function PortfolioScreen() {
  const cardsNodeRef = createRef<HTMLDivElement>();

  const svgHorizontalNodeRef = createRef<SVGSVGElement>();
  const svgVerticalNodeRef = createRef<SVGSVGElement>();
  const { isVisible: isHorizontalVisible } = useElementInViewport({
    element: svgHorizontalNodeRef,
  });
  const { isVisible: isVerticalVisible } = useElementInViewport({
    element: svgVerticalNodeRef,
  });

  const { y: y1 } = useLinePoint({
    maxIndent: lineRechtagleHeight,
    isVisible: isHorizontalVisible,
  });
  const { y: y2 } = useLinePoint({
    maxIndent: lineRechtagleHeight,
    isVisible: isHorizontalVisible,
  });

  const { y: x1 } = useLinePoint({
    maxIndent: lineRechtagleHeight,
    inHalf: true,
    isVisible: isVerticalVisible,
  });
  const { y: x2 } = useLinePoint({
    maxIndent: lineRechtagleHeight,
    inHalf: true,
    isVisible: isVerticalVisible,
  });

  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    setWindowWidth(document.documentElement.clientWidth);
  }, []);

  const [heightOfCards, setHeightOfCards] = useState(0);
  useEffect(() => {
    if (cardsNodeRef.current) {
      setHeightOfCards(cardsNodeRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (cardsNodeRef.current) {
      setHeightOfCards(cardsNodeRef.current.getBoundingClientRect().height);

      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (cardsNodeRef.current) {
          setHeightOfCards(cardsNodeRef.current.getBoundingClientRect().height);
        }
      });
      resizeObserver.observe(cardsNodeRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [cardsNodeRef]);

  const { classes } = usePortfolioScreenStyles();
  const [showAll, setShowAll] = useState(false);

  return (
    <section
      id={"portfolio"}
      className={classes.section}
      style={{
        borderTop: windowWidth ? undefined : "1px solid #AE8054",
      }}
    >
      {windowWidth ? (
        <svg
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 2,
            width: "100%",
          }}
          ref={svgHorizontalNodeRef}
          viewBox={`0 0 ${windowWidth} ${lineRechtagleHeight}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            // stroke="#AE8054"
            // strokeWidth={1}
            fill="#fff"
            d={`M0 0L${windowWidth} 0L${windowWidth} ${y2}L0 ${y1}Z`}
          />

          <line x1="0" y1={y1} x2={windowWidth} y2={y2} stroke="#AE8054" />
        </svg>
      ) : null}

      <div className={classes.center}>
        <div
          ref={cardsNodeRef}
          style={{
            position: "relative",
            paddingTop: "50px",
          }}
        >
          <h2 className={classes.headline}>портфолио</h2>
          {heightOfCards ? (
            <svg
              ref={svgVerticalNodeRef}
              style={{
                position: "absolute",
                left: "50%",
                top: 0,
                transform: "translateX(-50%)",
              }}
              width={lineRechtagleHeight}
              height={heightOfCards}
              viewBox={`0 0 ${lineRechtagleHeight} ${heightOfCards}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1={x1}
                y1={0}
                x2={x2}
                y2={heightOfCards}
                stroke="#AE8054"
              />
            </svg>
          ) : (
            <div
              style={{
                width: 1,
                height: "100%",
                background: "#AE8054",
                left: "50%",
                top: 0,
                transform: "translateX(-50%)",
                position: "absolute",
              }}
            ></div>
          )}

          {portfolioData.map((portfolio, index) => {
            const isEven = index % 2 == 0;
            return index < 3 || showAll ? (
              <Work
                portfolio={portfolio}
                disableLine={index == 0}
                rightSide={!isEven}
                index={index}
              />
            ) : null;
          })}
        </div>
      </div>
      <div
        style={{
          borderTop: "1px solid #AE8054",
        }}
      >
        {showAll ? null : (
          <button onClick={() => setShowAll(true)} className={classes.showMore}>
            <span className={classes.center}>Показать еще</span>
          </button>
        )}
      </div>
    </section>
  );
}

function Work({
  rightSide,
  disableLine,
  portfolio,
  index,
}: {
  rightSide?: boolean;
  disableLine?: boolean;
  portfolio: (typeof portfolioData)[0];
  index: number;
}) {
  const cardNodeRef = createRef<HTMLAnchorElement>();
  const svgNodeRef = createRef<SVGSVGElement>();

  const [cardWidth, setCardWidth] = useState(0);
  useEffect(() => {
    if (cardNodeRef.current && !disableLine)
      setCardWidth(cardNodeRef.current.offsetWidth);
  }, []);

  const { isVisible } = useElementInViewport({ element: svgNodeRef });

  const { y: y1 } = useLinePoint({
    maxIndent: lineRechtagleHeight,
    inHalf: true,
    isVisible,
  });
  const { y: y2 } = useLinePoint({
    maxIndent: lineRechtagleHeight,
    inHalf: true,
    isVisible,
  });

  const { classes } = usePortfolioScreenStyles();

  return (
    <a
      className={classes.work}
      style={{
        borderTop: cardWidth
          ? undefined
          : disableLine
          ? undefined
          : "1px solid #AE8054",
      }}
      ref={cardNodeRef}
      href={portfolio.link}
      target="_blank"
      rel="nofollow"
    >
      {cardWidth ? (
        <svg
          ref={svgNodeRef}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 2,
            transform: "translateY(-50%)",
            width: "100%",
          }}
          viewBox={`0 0 ${cardWidth} ${lineRechtagleHeight}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="0" y1={y1} x2={cardWidth} y2={y2} stroke="#AE8054" />
        </svg>
      ) : null}
      <span
        css={{
          width: "45%",
          order: rightSide ? 3 : undefined,
          "& * img, & > span": {
            display: "block !important",
          },
        }}
      >
        <Image src={portfolio.imageLink} />
      </span>

      <span
        style={{
          width: "45%",
        }}
        className={classes.workInfo}
      >
        <span className={classes.workNumber}>{index + 1}</span>
        <span className={classes.workName}>{portfolio.name}</span>
      </span>
    </a>
  );
}

const animationSpeed = 30;
const easing = bezier(1, 0.09, 0.38, 0.56);

function useLinePoint({
  maxIndent,
  inHalf,
  isVisible,
}: {
  maxIndent: number;
  inHalf?: boolean;
  isVisible?: boolean;
}) {
  const [lastPointY, setLastPointY, lastPointYRef] = useStateRef(0);
  const [nextYDiff, setNextYDiff, nextYDiffRef] = useStateRef(0);
  const [y, setY, yRef] = useStateRef(inHalf ? maxIndent / 2 : 0);
  const [date, setDate, dateRef] = useStateRef(Date.now() + animationSpeed);
  const [percent, setPercent, percentRef] = useStateRef(0);

  const [localVisible, setLocalVisible, localVisibleRef] =
    useStateRef(isVisible);
  useEffect(() => {
    setLocalVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    function calcPoints() {
      if (
        typeof localVisibleRef.current == "boolean" &&
        !localVisibleRef.current
      ) {
      } else {
        if (percentRef.current > 1) {
          setPercent(0);
        } else if (percentRef.current == 0) {
          const nextY = Math.random() * maxIndent;

          setNextYDiff(nextY - yRef.current);

          setLastPointY(yRef.current);

          setPercent(0.01);
        } else {
          if (dateRef.current < Date.now()) {
            setPercent((p) => p + 0.01);

            setY(
              lastPointYRef.current +
                nextYDiffRef.current * easing(percentRef.current)
            );

            setDate(Date.now() + animationSpeed);
          }
        }
      }
      requestAnimationFrame(calcPoints);
    }
    requestAnimationFrame(calcPoints);
  }, []);
  return {
    y,
  };
}
