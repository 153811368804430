export const reviewsData: {
  name: string;
  link: string;
  text: string;
  image: string;
}[] = [
  {
    name: "Алексей Костин",
    link: "https://vk.com/mr.alexkostin",
    text: "Андрей - профи. Работает быстро и качественно. Код без багов. Мои бэкэндеры - звери. Не было еще ни одного фронтендера до Андрея, кто сработал бы так четко и в установленный срок. Очень доволен работой над фронтендом сайта моей конференции. Обязательно буду и дальше обращаться.",
    image:
      "https://sun4-18.userapi.com/s/v1/ig2/g0907WFgfY9u2-TRF5YVyewaAvRmCfAbK47rQfnxHmfhLy2LUYXYrhcsO-jTvqhV8894M9uisGDIRZK2bfuuTrLn.jpg?size=50x50&quality=95&crop=453,235,1028,1028&ava=1",
  },
  {
    name: "Вероника Герке",
    link: "https://vk.com/verounico",
    text: "Сделали лэндинг с анимациями и сложными иллюстрациями. Справились достаточно оперативно, также отдельно отмечу ключевое: ответственность и, что очень важно, — работу на результат. Приятно иметь дело с профессионалом",
    image:
      "https://sun4-22.userapi.com/s/v1/ig2/tlYZZe229TxHfGmUPLLwimqJqszSEyqvYGqHDnR6cWTx0lwpx8NYT0uFoYUgF9P7YJsDVFxo042GR0a7hp80PhUj.jpg?quality=95&crop=11,155,1678,1678&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280,1440x1440&ava=1&cs=50x50",
  },
  {
    name: "Максим Лепинских",
    link: "https://vk.com/maximlepinskih",
    text: "Успели поработать над 5 проектами с Андреем.Мне понравилось работать. Важный момент! Исполнители делятся на два вида: те, кто в любом случае доделает свою работу и те, кто устает работать и пытается быстрей завершить проект или вообще исчезает. Вот Андрей относится к первому типу исполнителей. Это дорогого стоит.",
    image:
      "https://sun4-17.userapi.com/s/v1/ig2/j2HPoFdmhK1qPhfKjD4whSK8uzRGcUlEu2ief2S1aLC1ML-bFi8Dcy3F1bmGhMkfYt2BWDbsovZ4zOgVIG54CZlU.jpg?size=50x50&quality=96&crop=53,46,1585,1585&ava=1",
  },
  {
    name: "Аркадий Камнев",
    link: "https://vk.com/arkadiykamnev",
    text: "Дизайнер нарисовал мне нетривиальные макеты корпоративного сайта. На уровне техники требовалось сменить CMS и прикрутить специализированные сервисы к лидогенерящим формам. К этому добавлялась интеграция с CRM Salesforce. Стало понятно, что реализация на уровне вёрстки будет не самая простая. Опыт взаимодействия с предыдущим верстальщиком из Черногории был неудачным, поэтому я решил посмотреть на рынок и вышел на Андрея. Что могу сказать? Мои технари и продавцы остались довольны, я, как маркетолог, тоже. Андрей – крайне ответственный и порядочный исполнитель. На все вопросы отвечает в мессенджерах максимально оперативно. Всегда подскажет и посоветует, если вы зашли в тупик и не можете найти решение. В моём случае в процессе вёрстки неоднократно менялся дизайн отдельных элементов сайта. Андрей без проблем всё переделал. А я всё ждал, когда же у него лопнет терпение. И не дождался) Если вы думаете, стоит обращаться к Андрею или нет – не думайте. Пишите-звоните прямо сейчас. Или вас опередит кто-то другой. И будет прав.",
    image:
      "https://sun4-19.userapi.com/s/v1/if1/GuL4eOq16lnadBShr9zWWH7IBFsu40r1crA6g7GxrC5gEWuX3btgayT4RL7BPUH75oYUz0Uj.jpg?size=50x50&quality=96&crop=129,0,2009,2009&ava=1",
  },
  {
    name: "Анастасия Подрябинкина",
    link: "https://vk.com/podryabinkinaa",
    text: "Работали с Андреем над двумя проектами, результатом очень довольна. Андрей отзывчивый и работающий на результат человек, выполняет всё быстро, терпеливо относится к правкам (уж без этого никак)))",
    image:
      "https://sun4-17.userapi.com/s/v1/ig2/N5h-0fhheL6Ox4nysorYEoG_59x4vLm145len217cMobZ_UyKqEFvkDaW7j63wxJMOCp0Ed_qo3w34OLE14RBQ7u.jpg?size=50x50&quality=96&crop=162,162,1296,1296&ava=1",
  },
  {
    name: "Алексей Забелин",
    link: "https://vk.com/a_zabelin",
    text: "Делали с Андреем несколько сайтов. Очень приятно работать с таким ответственным профессионалом! Благодарен за быструю и четкую работу в срок, уверен будем работать еще)Всем рекомендую обращаться к Андрею!",
    image:
      "https://sun4-21.userapi.com/s/v1/if1/jSkHm23yOr-EKE--8d96mH6YITpE6OhJ9ceHVRlZopE5ux_jonPWQcx0UWE-bdLHpCDATw.jpg?size=50x50&quality=96&crop=78,17,1147,1147&ava=1",
  },
  {
    name: "Иван Кунин",
    link: "https://vk.com/ivan_kunin",
    text: "Верстает быстро и качественно, ещё и цены более чем адекватные.Прям, сильно благодарен за качество и скорость работы.Сверстал мне 3 лендинга.",
    image:
      "https://sun4-20.userapi.com/s/v1/ig1/-bqef8IpDLWRWwHXQ8r-bIm6xp-JbuKzCg5XDbhzwYuFoZWXTgMac1i7qQCW0kFIkWMqKHrY.jpg?size=50x50&quality=96&crop=38,21,328,328&ava=1",
  },
  {
    name: "Костя Васильев",
    link: "https://vk.com/id298984236",
    text: "Основное - оценка 5 из 5.Работает быстро и качественно. Всегда выполняет задание или какие-то правки в обозначенный срок. Работой доволен и нет мыслей менять исполнителя. Советую!",
    image:
      "https://sun4-18.userapi.com/s/v1/if1/QehQTLyQJVJXQD0zL5LwpDZkkHN1YwgKU1Xsf4AJrie3l_lRhxavHVSSfLghQWA91wVy-Sjf.jpg?size=50x50&quality=96&crop=39,39,1102,1102&ava=1",
  },
  {
    name: "Ирина Щедухина",
    link: "https://vk.com/eri_create",
    text: "Работа понравилась. Андрей сделал верстку быстро и недорого, всегда был на связи, на все вопросы отвечал точно и по делу, без проблем внёс все правки и дополнения, которые возникли в процессе. Сотрудничеством осталась довольна.",
    image:
      "https://sun4-19.userapi.com/s/v1/ig2/kSOfNtoPK9yZpOg2WKyw5tnVju2DUOPvCxc88WI5yNIfLggsIeKWs8Zf5mODASEdLLwONSVvEl-s6Xt5eUOc8Hat.jpg?size=50x50&quality=95&crop=41,0,1859,1859&ava=1",
  },
  {
    name: "Дмитрий Гречишкин",
    link: "https://vk.com/djdimch",
    text: 'Узнал про Андрея, перелопатив весь интернет и внимательно изучил работы.Отношение к заказчику лояльное, подход к выполнению заказа был тщательным, общение это подтвердило.Понравилось при работе с Андреем, что не было "воды" в общении, максимально быстро, максимально профессионально, задача поставлена - задача выполнена)',
    image:
      "https://sun4-18.userapi.com/s/v1/ig2/6HEKW0FTfSsCvAsAcQfsq2JNcKxIorBWvCnLS6O2R5DXPVozqFKF7SiKmi5pJd_-9RpxBDgrk_Ghb9StT76JxXZ8.jpg?size=50x50&quality=96&crop=730,171,1441,1441&ava=1",
  },
  {
    name: "Никита Кокарев",
    link: "https://vk.com/nnkokarev",
    text: "Отличная работа, все супер. Работа выполнена в срок, без нареканий. Всегда на связи, четкие ответы на вопросы, профессионализм, порядочность. Выбрал тебя потому что, понравился сайт, все четко изложено, все понятно, прозрачное ценообразование.",
    image:
      "https://sun4-21.userapi.com/s/v1/if1/TfSTujJO6An3L69n2U8cDSluIbr57k4_85vWLTfSIEuEF8RwE1cMUeOFsI6En4iJMLF1nkbY.jpg?size=50x50&quality=96&crop=119,0,347,347&ava=1",
  },
  {
    name: "Артем Гирфанов",
    link: "https://vk.com/girfanov_a",
    text: "Отличный исполнитель! Работаю с Андреем больше 2 лет. Цена ниже рынка при высоком качестве услуг! Заказал уже 5 макетов из PSD",
    image:
      "https://sun4-22.userapi.com/s/v1/ig2/5slYs-IKQeOyYGZh__COGtHo_GfZf5USDlWl_UYSQiV9MXAygOuLtZGgXUnXafb0a6t7aJey8FORqVoYHNTm0j4n.jpg?size=50x50&quality=95&crop=0,649,1277,1277&ava=1",
  },
  {
    name: "Дмитрий Останин",
    link: "https://vk.com/torna",
    text: "Очень хороший специалист, выполнил работу выше ожидаемых похвал.",
    image:
      "https://sun4-22.userapi.com/s/v1/ig2/iCcSSuPE_SivVxWuXsQRuRsjRhW__mf6KR3XhG_DPwR0bd3VdfZ0jJj0uXX99ujv18YLwOACbd9FMZJAe5DuDUeg.jpg?quality=96&crop=751,0,1439,1439&as=32x32,48x48,72x72,108x108,160x160,240x240,360x360,480x480,540x540,640x640,720x720,1080x1080,1280x1280&ava=1&cs=50x50",
  },
];
