import { createStyles } from "@mantine/core";
import { mediaBreakpoints } from "utils/breakpoints";
import { colors } from "utils/colors";

const sideWidth = 32;
const gap = 30;

export const useReasonsScreenStyles = createStyles(
  (theme, _params, getRef) => ({
    sectionShell: {
      padding: "20px",
      [mediaBreakpoints.mobile]: {
        padding: "10px",
      },
    },
    section: {
      border: "1px solid #F6EADF",
      padding: 50,
      position: "relative",
      borderRadius: 8,
      overflow: "hidden",
      background: "#EDCFB0",
      [mediaBreakpoints.mobile]: {
        padding: 20,
        margin: "10px",
      },
    },
    headline: {
      fontSize: 49,
      lineHeight: "100%",
      width: "100%",
      textTransform: "uppercase",
      color: "#C0946A",
      marginBottom: 30,
      [mediaBreakpoints.mobile]: {
        fontSize: 40,
        textAlign: "center",
      },
    },

    shell: {
      maxWidth: 1000,
      padding: 30,
      margin: "0 auto",
      position: "relative",
      zIndex: 2,
      [mediaBreakpoints.mobile]: {
        padding: 15,
      },
    },

    reasons: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignItems: "stretch",
      gap: gap,
      position: "relative",
      [mediaBreakpoints.mobile]: {
        gap: 15,
      },
    },

    reason: {
      display: "flex",
      flexWrap: "wrap",
      background: "#EDCFB0",
      position: "relative",
      borderRadius: 12,
      padding: "24px 28px",
      width: `calc(50% - ${gap / 2}px)`,
      [mediaBreakpoints.tabletMiddleBefore]: {
        width: "100%",
      },
      [mediaBreakpoints.mobile]: {
        width: "100%",
      },
    },
    reasonLeft: {
      width: sideWidth,
      "& * [stroke]": {
        stroke: "#AE8054",
      },
      "& * [fill]": {
        fill: "#AE8054",
      },
      [mediaBreakpoints.mobile]: {
        width: "100%",
        marginBottom: 12,
      },
    },
    reasonRight: {
      width: `calc(100% - ${sideWidth}px)`,
      paddingLeft: 21,
      [mediaBreakpoints.mobile]: {
        width: "100%",
        paddingLeft: 0,
      },
    },
    reasonTitle: {
      color: "#AE8054",
      fontSize: 20,
      lineHeight: "100%",
      marginBottom: 22,
      marginTop: 5,
      fontFamily: "Seed",
      [mediaBreakpoints.mobile]: {
        marginTop: 0,
      },
    },
    reasonDescription: {
      color: "#6E5135",
      fontSize: 16,
      letterSpacing: "0.04em",
    },
  })
);
