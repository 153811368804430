import { createStyles } from "@mantine/core";
import { mediaBreakpoints } from "utils/breakpoints";

const bottomSideWidth = 47;

export const useReviewsScreenStyles = createStyles(
  (theme, _params, getRef) => ({
    section: {
      padding: "70px 15px 50px 15px",
      background: "#2C2926",
    },
    center: {
      maxWidth: 1200,
      margin: "0 auto",
    },
    headline: {
      fontSize: 49,
      color: "#DADADA",
      letterSpacing: "0.07em",
      lineHeight: "100%",
      marginBottom: 24,
      textTransform: "uppercase",
    },
    reviews: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    review: {
      width: "calc(50% - 15px)",
      border: "1px solid #3B3B3B",
      background: "#333",
      borderRadius: 4,
      padding: 50,
      marginBottom: 30,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      [mediaBreakpoints.tabletMiddleBefore]: {
        width: "100%",
        padding: 20,
      },
      [mediaBreakpoints.mobile]: {
        width: "100%",
        padding: 20,
      },
    },
    reviewIcon: {
      display: "block",
      marginBottom: 27,
    },
    reviewText: {
      fontSize: 20,
      color: "#ECE7E1",
      [mediaBreakpoints.tablet]: {
        fontSize: 16,
      },
      [mediaBreakpoints.mobile]: {
        fontSize: 16,
      },
    },
    reviewTextShell: {
      overflow: "hidden",
      transition: "0.2s",
    },
    reviewSpoiler: {
      marginTop: 8,
      height: 32,
      border: "none",
      background: "none",
      padding: "none",
      color: "#ECE7E1",
      fontSize: 20,
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-start",
    },
    reviewTop: {},
    reviewBottom: {
      display: "flex",
      alignItems: "center",
      color: "inherit",
      textDecoration: "none",
    },
    reviewName: {
      fontSize: 18,
      color: "#ECE7E1",
      lineHeight: "100%",
      width: `calc(100% - ${bottomSideWidth}px)`,
      fontFamily: "Seed",
    },
    reviewImage: {
      height: bottomSideWidth,
      width: bottomSideWidth,
      borderRadius: 4,
    },

    showAll: {
      fontSize: 49,
      letterSpacing: ".07em",
      fontFamily: "Seed",
      lineHeight: "100%",
      color: "#ECE7E1",
      height: 62,
      width: "calc(100% - 64px)",
      margin: "0 auto",
      textAlign: "center",
      background: "#333",
      display: "flex",
      alignItems: "center",
      border: "none",
      textTransform: "uppercase",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: 4,
      marginTop: 10,
      [mediaBreakpoints.mobile]: {
        fontSize: 22,
      },
    },
  })
);
