import { telegramLink } from "utils/telegramLink";
import { useContactScreenStyles } from "./contactScreenStyles";

export function ContactScreen() {
  const { classes, cx } = useContactScreenStyles();
  return (
    <section id={"contacts"} className={classes.section}>
      <a href={telegramLink} target="_blank" rel="nofollow" className={classes.shell}>
        Задайте ваш вопрос{" "}
        <span className={classes.mobileLine}>
          в <span className={classes.underline}>Телеграм</span>
        </span>
      </a>
    </section>
  );
}
