import { colors } from "utils/colors";

export const ScissorsIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10.5"
      y="12"
      width="20"
      height="2"
      rx="0.5"
      transform="rotate(30 10.5 12)"
      fill={colors.dark}
    />
    <rect
      x="10.0012"
      y="19"
      width="20"
      height="2"
      rx="0.5"
      transform="rotate(-30 10.0012 19)"
      fill={colors.dark}
    />
    <circle cx="8" cy="10" r="3.5" stroke={colors.dark} strokeWidth={2} />
    <circle cx="8" cy="22" r="3.5" stroke={colors.dark} strokeWidth={2} />
  </svg>
);
