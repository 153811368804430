import { CSSProperties, RefObject, useEffect, useState } from "react";
import useStateRef from "react-usestateref";
import bezier from "./../utils/bezier-easing/bezier-easing";
import { useElementInViewport } from "utils/useElementInViewport";

export default function () {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {/* <CurvedRechtagle width={1000} height={600} /> */}
    </div>
  );
}

type VerticalDirection = "top" | "bottom";

export function CurvedRechtagle({
  style,
  color = "#F6EADF",
  borderColor,
  curveIndent = 12,
  once,
  element,
  image,
  offset = 10,
  debug,
}: {
  style?: CSSProperties;
  color?: string;
  borderColor?: string;
  curveIndent?: number;
  once?: boolean;
  element: RefObject<HTMLDivElement>;
  image?: string;
  offset?: number;
  debug?: boolean;
}) {
  const indent = 100;

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const { isVisible } = useElementInViewport({ element });

  useEffect(() => {
    if (element.current) {
      setHeight(element.current.getBoundingClientRect().height);
      setWidth(element.current.getBoundingClientRect().width);
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (element.current) {
          setHeight(element.current.getBoundingClientRect().height);
          setWidth(element.current.getBoundingClientRect().width);
        }
      });

      resizeObserver.observe(element.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [element]);

  const shellWidth = width + indent * 2;
  const shellHeight = height + indent * 2;

  const { x: x1, y: y1 } = useRechtaglePoint({
    startX: indent,
    startY: indent,
    once,
    offset,
    isVisible,
    debug,
  });
  const { x: x2, y: y2 } = useRechtaglePoint({
    startX: width + indent,
    startY: indent,
    once,
    offset,
    isVisible,
  });
  const { x: x3, y: y3 } = useRechtaglePoint({
    startX: width + indent,
    startY: height + indent,
    once,
    offset,
    isVisible,
  });
  const { x: x4, y: y4 } = useRechtaglePoint({
    startX: indent,
    startY: height + indent,
    once,
    offset,
    isVisible,
  });

  const { x: x1Curve, y: y1Curve } = useRechtagleCurve({
    from: { x: x1, y: y1 },
    to: { x: x2, y: y2 },
    curveIndent,
  });

  const { x: x2Curve, y: y2Curve } = useRechtagleCurve({
    from: { x: x2, y: y2 },
    to: { x: x1, y: y1 },
    curveIndent,
  });
  const { x: x3Curve, y: y3Curve } = useRechtagleCurve({
    from: { x: x2, y: y2 },
    to: { x: x3, y: y3 },
    curveIndent,
  });

  const { x: x4Curve, y: y4Curve } = useRechtagleCurve({
    from: { x: x3, y: y3 },
    to: { x: x2, y: y2 },
    curveIndent,
  });
  const { x: x5Curve, y: y5Curve } = useRechtagleCurve({
    from: { x: x3, y: y3 },
    to: { x: x4, y: y4 },
    curveIndent,
  });

  const { x: x6Curve, y: y6Curve } = useRechtagleCurve({
    from: { x: x4, y: y4 },
    to: { x: x3, y: y3 },
    curveIndent,
  });
  const { x: x7Curve, y: y7Curve } = useRechtagleCurve({
    from: { x: x4, y: y4 },
    to: { x: x1, y: y1 },
    curveIndent,
  });

  const { x: x8Curve, y: y8Curve } = useRechtagleCurve({
    from: { x: x1, y: y1 },
    to: { x: x4, y: y4 },
    curveIndent,
  });

  if (width == 0 || height == 0) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: color,
          borderRadius: curveIndent,
          pointerEvents: "none",
          position: "absolute",
          left: 0,
          top: 0,
          border: `1px solid ${borderColor}`,
        }}
      ></div>
    );
  }

  const d = `M${x1Curve} ${y1Curve}L${x2Curve} ${y2Curve} Q${x2} ${y2} ${x3Curve} ${y3Curve}L${x4Curve} ${y4Curve} Q${x3} ${y3} ${x5Curve} ${y5Curve}L${x6Curve} ${y6Curve} Q${x4} ${y4} ${x7Curve} ${y7Curve} L${x8Curve} ${y8Curve} Q${x1} ${y1} ${x1Curve} ${y1Curve}Z`;

  return (
    <>
      <div
        style={{
          ...style,
          transform: `translateX(${-indent}px) translateY(${-indent}px)`,
          position: "absolute",
          left: 0,
          top: 0,
          pointerEvents: "none",
        }}
      >
        <svg
          width={shellWidth}
          height={shellHeight}
          viewBox={`0 0 ${shellWidth} ${shellHeight}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* <rect width="100%" height="100%" /> */}

          {image ? (
            <>
              <defs>
                <clipPath id="svg-draw">
                  <path d={d}></path>
                </clipPath>
              </defs>
              <image
                clip-path="url(#svg-draw)"
                x={indent - offset}
                y={indent - offset}
                height={height + offset * 2}
                width={width + offset * 2}
                xlinkHref={image}
                preserveAspectRatio="xMidYMid slice"
              />
            </>
          ) : (
            <>
              <path
                strokeWidth={borderColor ? 1 : undefined}
                stroke={borderColor}
                fill={color}
                d={d}
              />
            </>
          )}
        </svg>
      </div>
    </>
  );
}

function useRechtagleCurve({
  from,
  to,
  curveIndent,
}: {
  from: { x: number; y: number };
  to: { x: number; y: number };
  curveIndent: number;
}) {
  const angleRadians = Math.atan(
    Math.abs(to.x - from.x) / Math.abs(to.y - from.y)
  );
  const angleDegrees = angleRadians * (180 / Math.PI);
  const firstVerticalAngleDirection: VerticalDirection =
    to.y < from.y ? "top" : "bottom";

  const firstHorizontalAngleDirection = to.x < from.x ? "left" : "right";

  const horizontalKath = curveIndent * Math.cos(Math.PI / 2 - angleRadians);
  const verticalKath = curveIndent * Math.cos(angleRadians);

  return {
    x:
      from.x +
      (firstHorizontalAngleDirection == "right"
        ? horizontalKath
        : -horizontalKath),
    y:
      from.y +
      (firstVerticalAngleDirection == "bottom" ? verticalKath : -verticalKath),
  };
}

const animationSpeed = 30;
const easing = bezier(1, 0.09, 0.38, 0.56);

function useRechtaglePoint({
  startX,
  startY,
  once,
  offset,
  isVisible,
  debug,
}: {
  startX: number;
  startY: number;
  once?: boolean;
  offset: number;
  isVisible: boolean;
  debug?: boolean;
}) {
  const [initStartX, setInitStartX] = useState(startX);
  const [initStartY, setInitStartY] = useState(startY);

  const [lastPointX, setLastPointX, lastPointXRef] = useStateRef(startX);
  const [lastPointY, setLastPointY, lastPointYRef] = useStateRef(startY);

  const [x, setX, xRef] = useStateRef(startX);
  const [y, setY, yRef] = useStateRef(startY);

  const [percent, setPercent, percentRef] = useStateRef(0); // .99 * Math.random()
  const [nextXDiff, setNextXDiff, nextXDiffRef] = useStateRef(0);
  const [nextYDiff, setNextYDiff, nextYDiffRef] = useStateRef(0);
  const [date, setDate, dateRef] = useStateRef(Date.now() + animationSpeed);

  const [inc, setInc, incRef] = useStateRef(0);
  const [localVisible, setLocalVisible, localVisibleRef] =
    useStateRef(isVisible);
  useEffect(() => {
    setLocalVisible(isVisible);
  }, [isVisible]);

  useEffect(() => {
    function calcPoints() {
      if (percentRef.current > 1) {
        if (!once) setPercent(0);
      } else if (percentRef.current == 0) {
        setInc((inc) => inc + 1);
        const xDirection = Math.random() > 0.5 ? "plus" : "minus";
        const yDirection = Math.random() > 0.5 ? "plus" : "minus";

        const middleDisabledPlacePercent = 0.8;

        const nextPlaceForPoint = Math.random() > 0.5 ? "end" : "start";
        const sizeOfNotDisabledPlace = (1 - middleDisabledPlacePercent) / 2;
        const randomSizeOfNotDisabledPlace =
          Math.random() * (offset * sizeOfNotDisabledPlace);

        let maxIndent = randomSizeOfNotDisabledPlace;
        if (nextPlaceForPoint == "end") {
          maxIndent = offset - randomSizeOfNotDisabledPlace;
        }

        const nextX = startX + (xDirection == "plus" ? maxIndent : -maxIndent);
        const nextY = startY + (yDirection == "plus" ? maxIndent : -maxIndent);

        setNextXDiff(nextX - xRef.current);
        setNextYDiff(nextY - yRef.current);

        setLastPointX(xRef.current);
        setLastPointY(yRef.current);

        setPercent(0.01);
      } else {
        if (dateRef.current < Date.now() && localVisibleRef.current) {
          setPercent((p) => p + 0.01);

          setX(
            lastPointXRef.current +
              nextXDiffRef.current * easing(percentRef.current)
          );
          setY(
            lastPointYRef.current +
              nextYDiffRef.current * easing(percentRef.current)
          );

          setDate(Date.now() + animationSpeed);
          // console.log(0);
        }
      }
      requestAnimationFrame(calcPoints);
    }
    requestAnimationFrame(calcPoints);
  }, []);

  return {
    x: x - (initStartX - startX),
    y: y - (initStartY - startY),
  };
}
