export const breakpoints = {
  mobile: 600,
  tablet: 1240,
  tabletMiddle: 900,
};

export const mediaBreakpoints = {
  mobile: `@media (max-width: ${breakpoints.mobile}px)`,
  tabletMiddleBefore: `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tabletMiddle}px)`,
  tablet: `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tablet}px)`,
};
