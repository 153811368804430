import { createStyles } from "@mantine/core";
import { mediaBreakpoints } from "utils/breakpoints";

const mobileHorizontalPadding = 15;

export const usePortfolioScreenStyles = createStyles(
  (theme, _params, getRef) => ({
    section: {
      position: "relative",
      overflow: "hidden",
    },
    headline: {
      fontSize: 38,
      letterSpacing: ".04rem",
      lineHeight: "100%",
      fontWeight: 700,
      color: "#AE8054",
      textTransform: "uppercase",
      marginBottom: 33,
      [mediaBreakpoints.mobile]: {
        padding: `0 ${mobileHorizontalPadding}px`,
        fontSize: 23,
      },
    },

    center: {
      margin: "0 auto",
      maxWidth: 1200,
      padding: "0px 100px 0 400px",
      [mediaBreakpoints.tablet]: {
        padding: "0px 20px",
      },
      [mediaBreakpoints.mobile]: {
        padding: `0`,
      },
    },

    showMore: {
      textAlign: "center",
      width: "100%",
      height: 150,
      color: "#C0946A",
      fontSize: 43,
      letterSpacing: ".06rem",
      lineHeight: "100%",
      fontWeight: 700,
      border: "none",
      background: "none",
      cursor: "pointer",
      textTransform: "uppercase",
      "&:hover": {
        opacity: "0.75",
      },
      [mediaBreakpoints.mobile]: {
        height: 113,
        fontSize: 37,
      },
    },

    work: {
      textDecoration: "none",
      padding: "20px 0",
      display: "flex",
      justifyContent: "space-between",
      position: "relative",
      alignItems: "stretch",
      color: "inherit",
      [mediaBreakpoints.mobile]: {
        padding: `20px ${mobileHorizontalPadding}px`,
      },
    },
    workInfo: {
      display: "flex",
      alignContent: "space-between",
      flexWrap: "wrap",
    },
    workNumber: {
      color: "#96816D",
      fontSize: 50,
      lineHeight: "100%",
      fontFamily: "Seed",
      [mediaBreakpoints.mobile]: {
        display: "none",
      },
    },
    workName: {
      display: "block",
      fontSize: 16,
      color: "#6E5135",
      [mediaBreakpoints.mobile]: {
        fontSize: 14,
      },
    },
  })
);
