import { RefObject, useEffect, useState } from "react";

export function useElementInViewport({
  element,
}: {
  element: RefObject<HTMLDivElement | HTMLAnchorElement | SVGSVGElement>;
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (element.current) {
      const calcIsElementInViewport = () => {
        if (element.current) {
          const height = element.current.getBoundingClientRect().height;
          const top = element.current.getBoundingClientRect().top;

          if (top > window.innerHeight) {
            setIsVisible(false); // hidden bottom
          } else if (top + height < 0) {
            setIsVisible(false); // hidden top
          } else {
            setIsVisible(true);
          }
        }
      };
      calcIsElementInViewport();

      const scrollListener = (event: Event) => {
        calcIsElementInViewport();
      };

      window.addEventListener("scroll", scrollListener);
      return () => {
        window.removeEventListener("scroll", scrollListener);
      };
    }
  }, [element]);

  return { isVisible };
}
