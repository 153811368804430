import {
  AspectRatio,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Paper,
  SimpleGrid,
  Text,
  Title,
  Spoiler,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconPlus } from "@tabler/icons";
import { PageLoader } from "components/PageLoader/PageLoader";
import Head from "next/head";
import { useRouter } from "next/router";
import { createRef, memo, useEffect, useRef, useState } from "react";
import { trpc } from "utils/trpc";
import avatar from "static/avatar.png";
import avatar2 from "static/avatar2.png";

import { IsLoggedUserWrap } from "wrappers/IsLoggedUserWrap";

import { useMainPageStyles } from "hooks/useMainPageStyles";
import Image from "next/image";
import { ScrollIcon } from "icons/ScrollIcon";
import { services } from "data/services";
import { reasonsData } from "screens/ReasonsScreen/reasons";
import { isOdd } from "utils/isOdd";
import { colors } from "utils/colors";
import { TelegramIcon } from "icons/TelegramIcon";
import { HandShakeIcon } from "icons/HandShakeIcon";
import { portfolioData } from "screens/PortfolioScreen/portfolioData";
import { textFormat } from "utils/textFormat";
import { useReviewsScreenStyles } from "./reviewsScreenStyles";
import { reviewsData } from "./reviewsData";

export const ReviewsScreenMemo = memo(ReviewsScreen, () => true);
function ReviewsScreen() {
  const { classes } = useReviewsScreenStyles();

  const [showAll, setShowAll] = useState(false);
  return (
    <section id={"reviews"} className={classes.section}>
      <div className={classes.center}>
        <h2 className={classes.headline}>Отзывы</h2>
        <div className={classes.reviews}>
          {reviewsData.map((review, index) =>
            index < 4 || showAll ? <Review key={index} review={review} /> : null
          )}
        </div>
        {showAll ? null : (
          <button className={classes.showAll} onClick={() => setShowAll(true)}>
            показать еще
          </button>
        )}
      </div>
    </section>
  );
}

const maxHeight = 220;

function Review({ review }: { review: (typeof reviewsData)[0] }) {
  const { classes } = useReviewsScreenStyles();

  const spoilerRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (spoilerRef.current) {
      setHeight(spoilerRef.current.getBoundingClientRect().height);
      setWidth(spoilerRef.current.getBoundingClientRect().width);

      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (spoilerRef.current) {
          setHeight(spoilerRef.current.getBoundingClientRect().height);
          setWidth(spoilerRef.current.getBoundingClientRect().width);
        }
      });
      resizeObserver.observe(spoilerRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [spoilerRef]);

  const showSpoiler = height && height > maxHeight;

  return (
    <div className={classes.review}>
      <div className={classes.reviewTop}>
        <svg
          width="46"
          height="40"
          viewBox="0 0 46 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.reviewIcon}
        >
          <path
            d="M14.4999 0.166626C16.0028 0.166626 17.4441 0.763648 18.5069 1.82635C19.5696 2.88906 20.1666 4.3304 20.1666 5.83329V22.8333C20.1666 31.7016 15.4916 37.5468 6.68842 39.7483C6.3265 39.8417 5.94967 39.8626 5.57965 39.8097C5.20962 39.7569 4.85371 39.6313 4.53242 39.4403C4.21113 39.2493 3.9308 38.9966 3.7076 38.6968C3.48439 38.397 3.32272 38.056 3.23189 37.6934C3.14106 37.3308 3.12286 36.9538 3.17836 36.5842C3.23385 36.2146 3.36194 35.8596 3.55524 35.5396C3.74853 35.2197 4.00322 34.9412 4.30463 34.7202C4.60603 34.4991 4.9482 34.3399 5.31142 34.2516C11.6213 32.6735 14.4999 29.0751 14.4999 22.8333V20H5.99992C4.57029 20.0004 3.19331 19.4605 2.14503 18.4884C1.09675 17.5163 0.454641 16.1839 0.347419 14.7583L0.333252 14.3333V5.83329C0.333252 4.3304 0.930274 2.88906 1.99298 1.82635C3.05569 0.763648 4.49703 0.166626 5.99992 0.166626H14.4999ZM39.9999 0.166626C41.5028 0.166626 42.9441 0.763648 44.0069 1.82635C45.0696 2.88906 45.6666 4.3304 45.6666 5.83329V22.8333C45.6666 31.7016 40.9916 37.5468 32.1884 39.7483C31.8265 39.8417 31.4497 39.8626 31.0796 39.8097C30.7096 39.7569 30.3537 39.6313 30.0324 39.4403C29.7111 39.2493 29.4308 38.9966 29.2076 38.6968C28.9844 38.397 28.8227 38.056 28.7319 37.6934C28.6411 37.3308 28.6229 36.9538 28.6784 36.5842C28.7339 36.2146 28.8619 35.8596 29.0552 35.5396C29.2485 35.2197 29.5032 34.9412 29.8046 34.7202C30.106 34.4991 30.4482 34.3399 30.8114 34.2516C37.1213 32.6735 39.9999 29.0751 39.9999 22.8333V20H31.4999C30.0703 20.0004 28.6933 19.4605 27.645 18.4884C26.5968 17.5163 25.9546 16.1839 25.8474 14.7583L25.8333 14.3333V5.83329C25.8333 4.3304 26.4303 2.88906 27.493 1.82635C28.5557 0.763648 29.997 0.166626 31.4999 0.166626H39.9999Z"
            fill="#A9A8A8"
          />
        </svg>
        <div
          style={{
            height: isOpen ? height : maxHeight,
            position: "relative",
          }}
          className={classes.reviewTextShell}
        >
          <p ref={spoilerRef} className={classes.reviewText}>
            {review.text}
          </p>
          {showSpoiler ? (
            <svg
              style={{
                position: "absolute",
                bottom: -4,
                left: 0,
                width: "100%",
                // border: "1px solid #fff",
              }}
              height={isOpen ? 0 : "40"}
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient id="Gradient2" x1="0" x2="0" y1="0" y2="1">
                  <stop offset="0%" stop-color="#333" stop-opacity="0" />
                  <stop offset="100%" stop-color="#333" />
                </linearGradient>
              </defs>

              <rect
                id="rect1"
                x="0"
                y="0"
                width="100%"
                height="100%"
                fill="url(#Gradient2)"
              />
            </svg>
          ) : null}
        </div>
        <button
          className={classes.reviewSpoiler}
          style={{}}
          onClick={() => setIsOpen((o) => !o)}
        >
          {showSpoiler ? (isOpen ? "Свернуть" : "Раскрыть") : ""}
        </button>
      </div>
      <a
        target="_blank"
        rel="nofollow"
        href={review.link}
        className={classes.reviewBottom}
      >
        <span className={classes.reviewName}>{review.name}</span>
        <img className={classes.reviewImage} src={review.image} />
      </a>
    </div>
  );
}
