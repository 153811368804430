import {
  createRef,
  CSSProperties,
  memo,
  RefObject,
  useEffect,
  useState,
} from "react";
import useStateRef from "react-usestateref";
import bezier from "utils/bezier-easing/bezier-easing";
import { useReasonsScreenStyles } from "./reasonsScreenStyles";
import { reasonsData } from "./reasons";
import { useElementInViewport } from "utils/useElementInViewport";

const animationSpeed = 10;
const easing = bezier(1, 0.09, 0.38, 0.56);

export function ReasonsScreen() {
  const cardsNodeRef = createRef<HTMLDivElement>();
  const shellCardsNodeRef = createRef<HTMLDivElement>();
  const visibleTriggerNodeRef = createRef<HTMLDivElement>();

  const { classes } = useReasonsScreenStyles();
  const { isVisible } = useElementInViewport({
    element: visibleTriggerNodeRef,
  });

  return (
    <section id={"reasons"} className={classes.sectionShell}>
      <div className={classes.section} ref={shellCardsNodeRef}>
        {0 ? (
          <SVG
            isVisible={isVisible}
            cardsNodeRef={cardsNodeRef}
            shellCardsNodeRef={shellCardsNodeRef}
          />
        ) : null}
        <Center
          isVisible={isVisible}
          cardsNodeRef={cardsNodeRef}
          shellCardsNodeRef={shellCardsNodeRef}
        />
        <div className={classes.shell} ref={cardsNodeRef}>
          <h2 className={classes.headline}>Почему я?</h2>
          <div ref={visibleTriggerNodeRef} className={classes.reasons}>
            {reasonsData.map((reason, index) => (
              <Reason
                key={index}
                reason={reason}
                // parentHeight={height}
                // verticalIndent={innerVerticalIndent}
                // horizontalIndent={innerHorizontalIndent}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

function Center({
  cardsNodeRef,
  shellCardsNodeRef,
  isVisible,
}: {
  cardsNodeRef: RefObject<HTMLDivElement>;
  shellCardsNodeRef: RefObject<HTMLDivElement>;
  isVisible: boolean;
}) {
  const [shellCardsWidth, setShellCardsWidth] = useStateRef(0);
  const [shellCardsHeight, setShellCardsHeight] = useStateRef(0);

  useEffect(() => {
    if (shellCardsNodeRef.current) {
      setShellCardsWidth(shellCardsNodeRef.current.offsetWidth);
      setShellCardsHeight(shellCardsNodeRef.current.offsetHeight);

      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (shellCardsNodeRef.current) {
          setShellCardsWidth(shellCardsNodeRef.current.offsetWidth);
          setShellCardsHeight(shellCardsNodeRef.current.offsetHeight);
        }
      });

      resizeObserver.observe(shellCardsNodeRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [shellCardsNodeRef]);

  const [cardsWidth, setCardsWidth] = useStateRef(0);
  const [cardsHeight, setCardsHeight] = useStateRef(0);

  useEffect(() => {
    if (cardsNodeRef.current) {
      setCardsWidth(cardsNodeRef.current.offsetWidth);
      setCardsHeight(cardsNodeRef.current.offsetHeight);

      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (cardsNodeRef.current) {
          setCardsWidth(cardsNodeRef.current.offsetWidth);
          setCardsHeight(cardsNodeRef.current.offsetHeight);
        }
      });

      resizeObserver.observe(cardsNodeRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [cardsNodeRef]);

  useEffect(() => {
    setIsVisited((isVisited) => {
      if (isVisited) return true;
      if (isVisible) return true;
      return false;
    });
  }, [isVisible]);
  const [isVisited, setIsVisited] = useState(false);
  const [timeoutEnded, setTimeoutEnded] = useState(false);

  const speed = 3000;

  useEffect(() => {
    if (isVisited) {
      setTimeout(() => {
        setTimeoutEnded(true);
      }, speed);
    }
  }, [isVisited]);

  return (
    <>
      <div
        style={{
          width: !shellCardsWidth
            ? "100%"
            : isVisited
            ? cardsWidth
            : shellCardsWidth,
          height: !shellCardsWidth
            ? "100%"
            : isVisited
            ? cardsHeight
            : shellCardsHeight,
          background: "#F6EADF",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          transition: timeoutEnded ? undefined : `${speed / 1000}s`,
          transitionTimingFunction: "cubic-bezier(1, 0.09, 0.38, 0.56)",
          borderRadius: 8,
        }}
      ></div>
    </>
  );
}

function SVG({
  cardsNodeRef,
  shellCardsNodeRef,
  isVisible,
}: {
  cardsNodeRef: RefObject<HTMLDivElement>;
  shellCardsNodeRef: RefObject<HTMLDivElement>;
  isVisible: boolean;
}) {
  const [localVisible, setLocalVisible, localVisibleRef] =
    useStateRef(isVisible);
  useEffect(() => {
    setLocalVisible(isVisible);
  }, [isVisible]);

  const [x1WithoutOffset, setX1] = useStateRef(0);
  const [y1WithoutOffset, setY1] = useStateRef(0);

  const [x2WithoutOffset, setX2] = useStateRef(0);
  const [y2WithoutOffset, setY2] = useStateRef(0);

  const [x3WithoutOffset, setX3] = useStateRef(0);
  const [y3WithoutOffset, setY3] = useStateRef(0);

  const [x4WithoutOffset, setX4] = useStateRef(0);
  const [y4WithoutOffset, setY4] = useStateRef(0);

  const [date, setDate, dateRef] = useStateRef(Date.now() + animationSpeed);

  const [lastVerticalIndent, setLastVerticalIndent, lastVerticalIndentRef] =
    useStateRef(0);
  const [
    nextVerticalIndentDiff,
    setNextVerticalIndentDiff,
    nextVerticalIndentDiffRef,
  ] = useStateRef(0);

  const [
    lastHorizontalIndent,
    setLastHorizontalIndent,
    lastHorizontalIndentRef,
  ] = useStateRef(0);
  const [
    nextHorizontalIndentDiff,
    setNextHorizontalIndentDiff,
    nextHorizontalIndentDiffRef,
  ] = useStateRef(0);

  const [innerVerticalIndent, setInnerVerticalIndent, innerVerticalIndentRef] =
    useStateRef<number | undefined>(undefined);
  const [
    innerHorizontalIndent,
    setInnerHorizontalIndent,
    innerHorizontalIndentRef,
  ] = useStateRef<number | undefined>(undefined);

  const [percent, setPercent, percentRef] = useStateRef(0); // .99 * Math.random()

  const [offsetTop, setOffsetTop, offsetTopRef] = useStateRef<
    number | undefined
  >(undefined);
  const [offsetLeft, setOffsetLeft, offsetLeftRef] = useStateRef<
    number | undefined
  >(undefined);
  const [width, setWidth, widthRef] = useStateRef<number | undefined>(
    undefined
  );
  const [height, setHeight, heightRef] = useStateRef<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (cardsNodeRef.current) {
      setOffsetTop(cardsNodeRef.current.offsetTop);
      setOffsetLeft(cardsNodeRef.current.offsetLeft);
      setWidth(cardsNodeRef.current.offsetWidth);
      setHeight(cardsNodeRef.current.offsetHeight);
    }
  }, [cardsNodeRef]);

  const [direction, setDirection, directionRef] = useStateRef<"in" | "out">(
    "in"
  );

  useEffect(() => {
    function calcPoints() {
      if (
        typeof localVisibleRef.current == "boolean" &&
        !localVisibleRef.current
      ) {
      } else {
        if (percentRef.current > 1) {
          setPercent(0);
        } else if (percentRef.current == 0) {
          if (
            typeof offsetTopRef.current == "number" &&
            typeof offsetLeftRef.current == "number"
          ) {
            const maxIndent = 20;

            const nextVeticalIndent =
              directionRef.current == "in" ? 0 : maxIndent;
            const nextHorizontalIndent =
              directionRef.current == "in" ? 0 : maxIndent;

            setDirection((direction) => (direction == "in" ? "out" : "in"));

            const nextVerticalIndentDiff =
              nextVeticalIndent -
              (typeof innerVerticalIndentRef.current == "number"
                ? innerVerticalIndentRef.current
                : offsetTopRef.current);

            const nextHorizontalIndentDiff =
              nextHorizontalIndent -
              (typeof innerHorizontalIndentRef.current == "number"
                ? innerHorizontalIndentRef.current
                : offsetLeftRef.current);

            setNextVerticalIndentDiff(nextVerticalIndentDiff);
            setNextHorizontalIndentDiff(nextHorizontalIndentDiff);

            setLastVerticalIndent(
              typeof innerVerticalIndentRef.current == "number"
                ? innerVerticalIndentRef.current
                : offsetTopRef.current
            );
            setLastHorizontalIndent(
              typeof innerHorizontalIndentRef.current == "number"
                ? innerHorizontalIndentRef.current
                : offsetLeftRef.current
            );

            setPercent(0.01);
          }
        } else {
          if (Date.now() > dateRef.current) {
            setPercent((p) => p + 0.01);

            setInnerVerticalIndent(
              Number(
                (
                  lastVerticalIndentRef.current +
                  nextVerticalIndentDiffRef.current * easing(percentRef.current)
                ).toFixed(2)
              )
            );

            setInnerHorizontalIndent(
              Number(
                (
                  lastHorizontalIndentRef.current +
                  nextHorizontalIndentDiffRef.current *
                    easing(percentRef.current)
                ).toFixed(2)
              )
            );

            setDate(Date.now() + animationSpeed);

            if (
              typeof widthRef.current == "number" &&
              typeof heightRef.current == "number" &&
              typeof offsetLeftRef.current == "number" &&
              typeof offsetTopRef.current == "number" &&
              typeof innerVerticalIndentRef.current == "number" &&
              typeof innerHorizontalIndentRef.current == "number"
            ) {
              setX1(offsetLeftRef.current - innerHorizontalIndentRef.current);
              setY1(offsetTopRef.current - innerVerticalIndentRef.current);

              setX2(
                offsetLeftRef.current +
                  widthRef.current +
                  innerHorizontalIndentRef.current
              );
              setY2(offsetTopRef.current - innerVerticalIndentRef.current);

              setX3(
                offsetLeftRef.current +
                  widthRef.current +
                  innerHorizontalIndentRef.current
              );
              setY3(
                offsetTopRef.current +
                  heightRef.current +
                  innerVerticalIndentRef.current
              );

              setX4(offsetLeftRef.current - innerHorizontalIndentRef.current);
              setY4(
                offsetTopRef.current +
                  heightRef.current +
                  innerVerticalIndentRef.current
              );
            }
          }
        }
      }
      requestAnimationFrame(calcPoints);
    }
    requestAnimationFrame(calcPoints);
  }, []);

  const [initCardsWidth, setInitCardsWidth] = useStateRef(0);
  const [initCardsHeight, setInitCardsHeight] = useStateRef(0);

  const [cardsWidth, setCardsWidth] = useStateRef(0);
  const [cardsHeight, setCardsHeight] = useStateRef(0);

  useEffect(() => {
    if (shellCardsNodeRef.current) {
      setCardsWidth(shellCardsNodeRef.current.offsetWidth);
      setCardsHeight(shellCardsNodeRef.current.offsetHeight);

      setInitCardsWidth((width) =>
        !width && shellCardsNodeRef.current
          ? shellCardsNodeRef.current.offsetWidth
          : width
      );
      setInitCardsHeight((height) =>
        !height && shellCardsNodeRef.current
          ? shellCardsNodeRef.current.offsetHeight
          : height
      );

      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (shellCardsNodeRef.current) {
          setCardsWidth(shellCardsNodeRef.current.offsetWidth);
          setCardsHeight(shellCardsNodeRef.current.offsetHeight);
        }
      });

      resizeObserver.observe(shellCardsNodeRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [shellCardsNodeRef]);

  const x1 = x1WithoutOffset;
  const y1 = y1WithoutOffset;

  const x2 = x2WithoutOffset;
  const y2 = y2WithoutOffset;

  const x3 = x3WithoutOffset;
  const y3 = y3WithoutOffset;

  const x4 = x4WithoutOffset;
  const y4 = y4WithoutOffset;

  const rechtagleCurveSize = 12;

  return (
    <>
      {x1 ? (
        <svg
          width={cardsWidth}
          height={cardsHeight}
          viewBox={`0 0 ${cardsWidth} ${cardsHeight}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            pointerEvents: "none",
          }}
        >
          <mask id="mask1">
            <rect width="100%" height="100%" fill="white" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              fill="black"
              d={`M${x1 + rechtagleCurveSize} ${y1}L${
                x2 - rechtagleCurveSize
              } ${y2}Q${x2} ${y2} ${x2} ${y2 + rechtagleCurveSize}L${x3} ${
                y3 - rechtagleCurveSize
              }Q${x3} ${y3} ${x3 - rechtagleCurveSize} ${y3}L${
                x4 + rechtagleCurveSize
              } ${y4}Q${x4} ${y4} ${x4} ${y4 - rechtagleCurveSize}L${x1} ${
                y1 + rechtagleCurveSize
              }Q${x1} ${y1} ${x1 + rechtagleCurveSize} ${y1}Z`}
            />
          </mask>
          <rect
            width="100%"
            height="100%"
            fill="#EDCFB0"
            mask="url(#mask1)"
          ></rect>
        </svg>
      ) : null}
    </>
  );
}

function Reason({
  verticalIndent,
  horizontalIndent,
  parentHeight,
  reason,
}: {
  verticalIndent?: number;
  horizontalIndent?: number;
  parentHeight?: number;
  reason: (typeof reasonsData)[0];
}) {
  const cardNodeRef = createRef<HTMLDivElement>();
  const [offsetTop, setOffsetTop] = useState<number | undefined>(undefined);
  const [height, setHeight] = useStateRef(0);
  const [top, setTop] = useStateRef(0);
  const [left, setLeft] = useStateRef(0);

  const { classes } = useReasonsScreenStyles();

  useEffect(() => {
    if (cardNodeRef.current) {
      setHeight(cardNodeRef.current.offsetHeight);
      setTop(cardNodeRef.current.offsetTop);
      setLeft(cardNodeRef.current.offsetLeft);
    }
  }, []);

  return (
    <div ref={cardNodeRef} className={classes.reason}>
      {0 && typeof horizontalIndent == "number" ? (
        <RectagleWithCurveAngles
          style={{
            position: "absolute",
            right: "100%",
            top: 40,
          }}
          direction="horizontal"
          depth={left + horizontalIndent} // (parentHeight || 0) - height - top + verticalIndent
        />
      ) : null}
      <div className={classes.reasonLeft}>{reason.icon}</div>
      <div className={classes.reasonRight}>
        <p className={classes.reasonTitle}>{reason.title}</p>
        <p className={classes.reasonDescription}>{reason.description}</p>
      </div>
    </div>
  );
}

function RectagleWithCurveAngles({
  depth,
  direction = "horizontal",
  style,
}: {
  depth: number;
  direction?: "vertical" | "horizontal";
  style: CSSProperties;
}) {
  const size = 30;
  const curveSize = 12;
  const sizeWithCurve = size + curveSize * 2;
  const reservePixels = 6;
  const depthWithReserve = depth + reservePixels * 2;

  return (
    <div
      style={{
        ...style,
        width: direction == "horizontal" ? depth : sizeWithCurve,
        height: direction == "horizontal" ? sizeWithCurve : depth,
      }}
    >
      <svg
        width={direction == "horizontal" ? depthWithReserve : sizeWithCurve}
        height={direction == "horizontal" ? sizeWithCurve : depthWithReserve}
        viewBox={
          direction == "horizontal"
            ? `0 0 ${depthWithReserve} ${sizeWithCurve}`
            : `0 0 ${sizeWithCurve} ${depthWithReserve}`
        }
        xmlns="http://www.w3.org/2000/svg"
        style={{
          display: "block",
          transform:
            direction == "horizontal"
              ? `translateX(${-reservePixels}px)`
              : `translateY(${-reservePixels}px)`,
        }}
      >
        <path
          fill="#EDCFB0"
          style={{
            willChange: "d",
          }}
          d={
            direction == "horizontal"
              ? `M0 0L${reservePixels} 0Q${reservePixels} ${curveSize} ${
                  curveSize + reservePixels
                } ${curveSize}L${
                  depthWithReserve - curveSize - reservePixels
                } ${curveSize}Q${
                  depthWithReserve - reservePixels
                } ${curveSize} ${
                  depthWithReserve - reservePixels
                } 0L${depthWithReserve} 0L${depthWithReserve} ${sizeWithCurve}L${
                  depthWithReserve - reservePixels
                } ${sizeWithCurve}Q${depthWithReserve - reservePixels} ${
                  sizeWithCurve - curveSize
                } ${depthWithReserve - reservePixels - curveSize} ${
                  sizeWithCurve - curveSize
                }L${curveSize + reservePixels} ${
                  sizeWithCurve - curveSize
                }Q${reservePixels} ${
                  sizeWithCurve - curveSize
                } ${reservePixels} ${sizeWithCurve}L0 ${sizeWithCurve}Z`
              : `M0 0L0 ${reservePixels}Q${curveSize} ${reservePixels} ${curveSize} ${
                  curveSize + reservePixels
                }L${curveSize} ${
                  depthWithReserve - curveSize - reservePixels
                }Q${curveSize} ${depthWithReserve - reservePixels} 0 ${
                  depthWithReserve - reservePixels
                }L0 ${depthWithReserve}L${sizeWithCurve} ${depthWithReserve}L${sizeWithCurve} ${
                  depthWithReserve - reservePixels
                }Q${sizeWithCurve - curveSize} ${
                  depthWithReserve - reservePixels
                } ${sizeWithCurve - curveSize} ${
                  depthWithReserve - curveSize - reservePixels
                }L${sizeWithCurve - curveSize} ${curveSize + reservePixels}Q${
                  sizeWithCurve - curveSize
                } ${reservePixels} ${sizeWithCurve} ${reservePixels}L${sizeWithCurve} 0Z`
          }
        />
      </svg>
    </div>
  );
}
