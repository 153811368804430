import {
  AspectRatio,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Paper,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconPlus } from "@tabler/icons";
import { PageLoader } from "components/PageLoader/PageLoader";
import Head from "next/head";
import { useRouter } from "next/router";
import { memo, useEffect, useState } from "react";
import { trpc } from "utils/trpc";

import { IsLoggedUserWrap } from "wrappers/IsLoggedUserWrap";

import { useMainPageStyles } from "hooks/useMainPageStyles";
import Image from "next/image";
import { ScrollIcon } from "icons/ScrollIcon";
import { services } from "data/services";
import { reasonsData } from "screens/ReasonsScreen/reasons";
import { isOdd } from "utils/isOdd";
import { colors } from "utils/colors";
import { TelegramIcon } from "icons/TelegramIcon";
import { HandShakeIcon } from "icons/HandShakeIcon";
import { portfolioData } from "screens/PortfolioScreen/portfolioData";
import { textFormat } from "utils/textFormat";
import { ReviewsScreenMemo } from "screens/ReviewsScreen/ReviewsScreen";
import { ReasonsScreen } from "screens/ReasonsScreen/ReasonsScreen";
import { FirstScreen } from "screens/FirstScreen/FirstScreen";
import { PortfolioScreen } from "screens/PortfolioScreen/PortfolioScreen";
import { ContactScreen } from "screens/ContactScreen/ContactScreen";

export default function IndexPage() {
  // const createSite = trpc.useMutation('user.createSite', {
  //   onError: error => {
  //     showNotification({
  //       title: error.message,
  //       message: '',
  //       color: 'red',
  //     })
  //   },
  // })

  const router = useRouter();

  // const user = trpc.useQuery(
  //   [
  //     'user.getUserSites',
  //     {
  //       isArchive: false,
  //     },
  //   ],
  //   {
  //     refetchInterval: 500,
  //   }
  // )

  const { classes, cx } = useMainPageStyles();

  const countOfScreens = 7;

  return (
    <>
      <Head>
        <title>Кододел - Лобанов Андрей</title>
      </Head>

      <FirstScreen />
      <PortfolioScreen />
      <ReasonsScreen />
      <ReviewsScreenMemo />
      <ContactScreen />
    </>
  );
}
