import { colors } from "utils/colors";

export const PencilIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_223_211)">
      <path
        d="M11.4285 27.9772L1.14282 30.8572L4.02282 20.5714L22.8571 1.82858C23.07 1.61088 23.3242 1.4379 23.6049 1.3198C23.8855 1.2017 24.1869 1.14087 24.4914 1.14087C24.7959 1.14087 25.0973 1.2017 25.3779 1.3198C25.6586 1.4379 25.9128 1.61088 26.1257 1.82858L30.1714 5.89716C30.3856 6.10964 30.5557 6.36245 30.6717 6.64098C30.7878 6.91952 30.8475 7.21827 30.8475 7.52001C30.8475 7.82175 30.7878 8.12051 30.6717 8.39905C30.5557 8.67758 30.3856 8.93038 30.1714 9.14287L11.4285 27.9772Z"
        stroke={colors.dark}
        stroke-linecap="round"
        stroke-linejoin="round"
        strokeWidth={2}
      />
    </g>
    <defs>
      <clipPath id="clip0_223_211">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
