import { createStyles } from "@mantine/core";
import { mediaBreakpoints } from "utils/breakpoints";

export const useContactScreenStyles = createStyles(
  (theme, _params, getRef) => ({
    section: {
      padding: "33px",
      overflow: "hidden",
    },
    shell: {
      background: "#C3D8E8",
      borderRadius: 4,
      height: 500,
      fontSize: 52,
      letterSpacing: "0.07em",
      lineHeight: "100%",
      fontWeight: 700,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      color: "#42444A",
      whiteSpace: "pre-wrap",
      textDecoration: "none",
      transition: "0.3s",
      "&:hover": {
        color: "#C3D8E8",
        background: "#42444A",
      },
      [mediaBreakpoints.mobile]: {
        fontSize: 22,
        lineHeight: "130%",
      },
    },
    underline: {
      textDecoration: "underline",
    },
    mobileLine: {
      [mediaBreakpoints.mobile]: {
        display: "block",
      },
    },
  })
);
